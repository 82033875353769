import axios from 'axios'
import Vue from 'vue'

import apiService from "@/http/ApiService";

export function examQuestionsListFromServerAction (context, code) {
  apiService.getExamTodoList(code)
  // axios.get('exam/todo/' + code + '/list')
    .then((response) => {
      context.commit('updateExamQuestionsList', response.data)
    })
    .catch((error) => {
      Vue.$toast.error(error.message)
    })
}
