// Import the functions you need from the SDKs you need
import {initializeApp, getApps} from "firebase/app";
import {getMessaging, getToken, onMessage, isSupported} from 'firebase/messaging';

const firebaseConfig = {
    apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
    authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.VUE_APP_FIREBASE_APP_ID,
    measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
};

const apps = getApps();

// Initialize Firebase
const firebaseApp = !apps.length ? initializeApp(firebaseConfig) : apps[0];

const messaging = (async () => {
    console.log("messaging method is called")
    try {
        const isSupportedBrowser = await isSupported();
        if (isSupportedBrowser) {
            return getMessaging(firebaseApp);
        }
        console.log('Firebase not supported this browser');
        return null;
    } catch (err) {
        console.log(err);
        return null;
    }
})();

export const onMessageListener = async () =>
    new Promise((resolve) =>
        (async () => {
            const messagingResolve = await messaging;
            if (messagingResolve)
                onMessage(messagingResolve, async (payload) => {
                    resolve(payload);
                });
        })()
    );
export const getDeviceToken = async () => {
    const messagingResolve = await messaging;
    if (messagingResolve) {
        try {
            const status = await Notification.requestPermission();
            const currentToken = await getToken(messagingResolve, {
                vapidKey: process.env.VUE_APP_FIREBASE_VAPIDKEY
            });
            if (status && status === 'granted') {
                console.log('notification permission granted.');
                if (currentToken) {
                    return currentToken;
                } else {
                    console.log('No registration token available. Request permission to generate one.');
                }
            } else {
                console.log('notification permission not granted!');
            }
        } catch (error) {
            console.log(error);
        }
    }
};