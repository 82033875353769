import Vue from "vue";
import Vuex from "vuex";
import VuexPersist from 'vuex-persist';

import state from "./state"
import getters from "./getters"
import mutations from "./mutations"
import actions from "./actions"

import examModule from './modules/exam'

Vue.use(Vuex);

const vuexLocalStorage = new VuexPersist({
  key: 'store', // The key to store the state on in the storage provider.
  storage: window.localStorage, // or window.sessionStorage or localForage
  // Function that passes the state and returns the state with only the objects you want to store.
  // reducer: state => state,
  // Function that passes a mutation and lets you decide if it should update the state in localStorage.
  // filter: mutation => (true)
  reducer: (state) => ({
      locale: state.locale,

      status: state.status,
      authenticated: state.authenticated,
      token: state.token,
      firebaseToken: state.firebaseToken,

      mobileNumber: state.mobileNumber,
      clientType: state.clientType,
      clientId: state.clientId,
      doctorId: state.doctorId,
      profileFilled: state.profileFilled,
      clinicId: state.clinicId,

      notificationActive: state.notificationActive,
      threeDotsActive: state.threeDotsActive,
      threeDotsMenu: state.threeDotsMenu,
      helloActive: state.helloActive,
      backActive: state.backActive,
      toolbarTitleActive: state.toolbarTitleActive,
      toolbarTitle: state.toolbarTitle,
      fullName: state.fullName,
      notificationCount: state.notificationCount,
      paymentStatus: state.orderId,
      paymentAmount: state.paymentAmount
  })
})


export default new Vuex.Store({
  state,
  getters,
  actions,
  mutations,
  plugins: [vuexLocalStorage.plugin],
  modules: {
    exam: examModule
  }
})