/* eslint-disable no-unused-vars */
import Vue from "vue";
import Router from "vue-router";
import store from "@/store/store.js";
import i18n from "@/plugins/i18n/i18n.js";

Vue.use(Router);

//--------------------------------------------------------------------------
//CONTAINERS
//--------------------------------------------------------------------------
const TheContainerAppUser = () => import("@/components/user/Container.vue");
const TheContainerAppDoctor = () => import("@/components/doctor/Container.vue");

const Page404 = () => import("@/views/Pages/Page404.vue");
//--------------------------------------------------------------------------
//AUTH
//--------------------------------------------------------------------------
const Auth = () => import("@/views/Auth/Auth.vue");
const Login = () => import("@/views/Auth/Login.vue");
const VerificationCode = () => import("@/views/Auth/VerificationCode.vue");
const RegisterUser = () => import("@/views/Auth/RegisterUser.vue");
const RegisterDoctor = () => import("@/views/Auth/RegisterDoctor.vue");

const VerifyPayment = () => import("@/views/Payment/VerifyPayment.vue");

//--------------------------------------------------------------------------
//APPLICATION - USER
//--------------------------------------------------------------------------
const User_Home = () => import("@/views/User/Main/Home.vue");
const Notifications = () => import("@/views/User/Notifications/Notifications.vue");

const Appointments = () => import("@/views/User/Appointments/Appointments.vue");

const Exercises = () => import("@/views/User/Exercise/Exercises.vue");
const ExerciseDetails = () => import("@/views/User/Exercise/ExerciseDetails.vue");
const ExerciseDetailsTodo = () => import("@/views/User/Exercise/ExerciseDetailsTodo.vue");

const Exams = () => import("@/views/User/Exam/Exams.vue");
const ExamFirst = () => import("@/views/User/Exam/ExamFirst.vue");
const ExamDetails = () => import("@/views/User/Exam/ExamDetails.vue");
const ExamConfirmation = () => import("@/views/User/Exam/ExamConfirmation.vue");
const ExamDone = () => import("@/views/User/Exam/ExamDone.vue");
const ExamResult = () => import("@/views/User/Exam/ExamResult.vue");
const ExamQuestion = () => import("@/views/User/Exam/ExamQuestion.vue");

const Questions = () => import("@/views/User/Questions/Questions.vue");
const AddQuestion = () => import("@/views/User/Questions/AddQuestion.vue");
const EditQuestion = () => import("@/views/User/Questions/EditQuestion.vue");
const SingleQusetion = () => import("@/views/User/Questions/SingleQusetion.vue");

const Explore = () => import("@/views/User/Explore/Explore.vue");
const Contents = () => import("@/views/User/Explore/Contents.vue");
const Podcasts = () => import("@/views/User/Explore/Podcasts.vue");
const Video_Details = () => import("@/views/User/Explore/Video.vue");
const Post_Details = () => import("@/views/User/Explore/Post.vue");
const Podcast_Details = () => import("@/views/User/Explore/Podcast.vue");

const Doctor_Podcast_Details = () => import("@/views/Doctor/Explore/Podcast.vue");

const Specialists = () => import("@/views/User/Specialists/Specialists.vue");
const Specialist = () => import("@/views/User/Specialists/Specialist.vue");

const ClinicDetails = () => import("@/views/User/Clinic/Details.vue");
const ClinicContents = () => import("@/views/User/Clinic/Contents.vue");
const ClinicQuestions = () => import("@/views/User/Clinic/Questions.vue");

const Search = () => import("@/views/User/Search/Search.vue");

const Profile = () => import("@/views/User/Profile/Profile.vue");
const ProfileEdit = () => import("@/views/User/Profile/Edit.vue");
const ProfileFavorites = () => import("@/views/User/Profile/Favorites.vue");
const ProfileWallet = () => import("@/views/User/Profile/Wallet.vue");
const ProfileQuestions = () => import("@/views/User/Profile/Questions.vue");
const ProfileSetting = () => import("@/views/User/Profile/Setting.vue");

const Moods = () => import("@/views/User/Mood/Mood.vue");


//--------------------------------------------------------------------------
//APPLICATION - DOCTOR
//--------------------------------------------------------------------------
const Doctor_Home = () => import("@/views/Doctor/Main/Home.vue");
const Doctor_Explore = () => import("@/views/Doctor/Explore/Explore.vue");
const Doctor_Contents = () => import("@/views/Doctor/Explore/Contents.vue");
const Doctor_Post_Details = () => import("@/views/Doctor/Explore/Post.vue");
const Doctor_Post_Edit = () => import("@/views/Doctor/Explore/EditPost.vue");
const Doctor_Video_Details = () => import("@/views/Doctor/Explore/Video.vue");
const Doctor_Video_Edit = () => import("@/views/Doctor/Explore/EditVideo.vue");
const Doctor_Create_Content = () => import("@/views/Doctor/Explore/Create.vue");

const Doctor_Questions = () => import("@/views/Doctor/Questions/Questions.vue");
const Doctor_SingleQusetion = () => import("@/views/Doctor/Questions/SingleQusetion.vue");
const Doctor_AddAnswer = () => import("@/views/Doctor/Questions/AddAnswer.vue");
const Doctor_EditAnswer = () => import("@/views/Doctor/Questions/EditAnswer.vue");

const Doctor_Specialist = () => import("@/views/Doctor/Specialists/Specialist.vue");
const Doctor_Followers = () => import("@/views/Doctor/Specialists/Followers.vue");
const Doctor_Profile = () => import("@/views/Doctor/Profile/Profile.vue");
const Doctor_ProfileEdit = () => import("@/views/Doctor/Profile/Edit.vue");
const Doctor_ProfileEditCallInfo = () => import("@/views/Doctor/Profile/EditCallInfo.vue");
const Doctor_ProfileFavorites = () => import("@/views/Doctor/Profile/Favorites.vue");

const Doctor_Map = () => import("@/views/Doctor/Map/Map.vue");

const Doctor_MoodsOfClient = () => import("@/views/Doctor/Mood/Mood.vue");

const Doctor_Notifications = () => import("@/views/Doctor/Notifications/Notifications.vue");

const Doctor_ClinicDetails = () => import("@/views/Doctor/Clinic/Details.vue");
const Doctor_ClinicContents = () => import("@/views/Doctor/Clinic/Contents.vue");
const Doctor_ClinicQuestions = () => import("@/views/Doctor/Clinic/Questions.vue");

const Doctor_ClinicOwner = () => import("@/views/Doctor/ClinicOwner/Owner.vue");
const Doctor_ClinicOwner_Financial = () => import("@/views/Doctor/ClinicOwner/Financial.vue");
const Doctor_ClinicOwner_Contents = () => import("@/views/Doctor/ClinicOwner/Contents.vue");
const Doctor_ClinicOwner_Exams = () => import("@/views/Doctor/ClinicOwner/Exams.vue");
const Doctor_ClinicOwner_Exercises = () => import("@/views/Doctor/ClinicOwner/Exercises.vue");
const Doctor_ClinicOwner_Questions = () => import("@/views/Doctor/ClinicOwner/Questions.vue");
const Doctor_ClinicOwner_Schedules = () => import("@/views/Doctor/ClinicOwner/Schedules.vue");
const Doctor_ClinicOwner_Members = () => import("@/views/Doctor/ClinicOwner/Members.vue");
const Doctor_ClinicOwner_Edit = () => import("@/views/Doctor/ClinicOwner/Edit.vue");

const Doctor_ClinicMember = () => import("@/views/Doctor/ClinicMember/Member.vue");

const Doctor_Exams = () => import("@/views/Doctor/Exams/Exams.vue");
const Doctor_ExamPreview = () => import("@/views/Doctor/Exams/Preview.vue");
const Doctor_ExamNewRequest = () => import("@/views/Doctor/Exams/New.vue");
const Doctor_ExamHistory = () => import("@/views/Doctor/Exams/History.vue");
const Doctor_ExamResult = () => import("@/views/Doctor/Exams/Result.vue");

const Doctor_Exercises = () => import("@/views/Doctor/Exercises/Exercises.vue");
const Doctor_CreateExercise = () => import("@/views/Doctor/Exercises/Create.vue");
const Doctor_EditExercise = () => import("@/views/Doctor/Exercises/Edit.vue");
const Doctor_ExerciseNewRequest = () => import("@/views/Doctor/Exercises/New.vue");
const Doctor_ExerciseHistory = () => import("@/views/Doctor/Exercises/History.vue");
const Doctor_ExerciseResult = () => import("@/views/Doctor/Exercises/Result.vue");

const Doctor_Financial = () => import("@/views/Doctor/Financial/Financial.vue");
const Doctor_Transactions = () => import("@/views/Doctor/Financial/Transactions.vue");
const Doctor_Settlements = () => import("@/views/Doctor/Financial/Settlements.vue");
const Doctor_SettlementDetails = () => import("@/views/Doctor/Financial/SettlementDetails.vue");

const Doctor_Schedule = () => import("@/views/Doctor/Schedule/Schedule.vue");


const router = new Router({
  mode: "history", // https://router.vuejs.org/api/#mode
  linkActiveClass: "active",
  scrollBehavior: () => ({ y: 0 }),
  routes: [
    {
      path: "",
      component: {
        render(c) {
          return c("router-view");
        },
      },
      children: [
        {
          path: "/",
          redirect: "/auth",
          meta: { title: "" },
        },
        {
          path: "/auth",
          name: "Auth",
          component: Auth,
          meta: { title: "ورود یا ثبت نام" },
        },
        {
          path: "/login",
          name: "Login",
          component: Login,
          meta: { title: "ورود یا ثبت نام" },
        },
        {
          path: "/register-client",
          name: "RegisterClient",
          component: RegisterUser,
          meta: {
            requiresAuth: true,
            title: "تکمیل پروفایل",
          },
        },
        {
          path: "/verify-payment",
          name: "VerifyPayment",
          component: VerifyPayment,
          meta: {
            requiresAuth: false,
          },
        },
        {
          path: "/register-specialist",
          name: "RegisterSpecialist",
          component: RegisterDoctor,
          meta: {
            requiresAuth: false,
            title: "تکمیل پروفایل",
          },
        },
        {
          path: "/verification-code",
          name: "VerificationCode",
          component: VerificationCode,
          meta: { title: "تایید کد" },
        }
      ],
    },
    {
      path: "/c",
      name: "User",
      redirect: "/c/home",
      meta: { title: "" },
      component: TheContainerAppUser,
      children: [
        {
          path: "/c/home",
          name: "HomeUser",
          component: User_Home,
          meta: {
            requiresAuth: true,
            title: "",
          },
        },
        {
          path: "/c/appointments",
          name: "Appointments",
          component: Appointments,
          meta: {
            requiresAuth: true,
            title: i18n.t('Appointments')
          }
        },
        {
          path: "/c/questions/add",
          name: "AddQuestion",
          component: AddQuestion,
          meta: {
            requiresAuth: true,
            title: i18n.t('Questions')
          }
        },
        {
          path: "/c/questions/:questionId(\\d+)/edit",
          name: "EditQuestion",
          component: EditQuestion,
          meta: {
            requiresAuth: true,
            title: i18n.t('Questions')
          }
        },
        {
          path: "/c/questions",
          component: Questions,
          meta: {
            requiresAuth: true,
            title: i18n.t('Questions')
          }
        },
        {
          path: "/c/questions/:questionId(\\d+)",
          name: "SingleQusetion",
          component: SingleQusetion,
          meta: {
            requiresAuth: true,
            title: i18n.t('Questions')
          }
        },
        {
          path: "/c/exams",
          name: "Exams",
          component: Exams,
          meta: {
            requiresAuth: true,
            title: i18n.t('Exams')
          }
        },
        {
          path: "/c/exercises",
          name: "Exercises",
          component: Exercises,
          meta: {
            requiresAuth: true,
            title: i18n.t('Exercise')
          }
        },
        {
          path: "/c/exercises/:id(\\d+)",
          name: "ExerciseDetails",
          component: ExerciseDetails,
          meta: {
            requiresAuth: true,
            title: i18n.t('Exercise')
          }
        },
        {
          path: "/c/exercises/:id(\\d+)/todo",
          name: "ExerciseDetailsTodo",
          component: ExerciseDetailsTodo,
          meta: {
            requiresAuth: true,
            title: i18n.t('Exercise')
          }
        },
        {
          path: "/c/exams/:code",
          component: ExamFirst,
          meta: {
            requiresAuth: true,
            title: i18n.t('Exam details')
          },
          children: [
            {
              path: '/',
              name: 'ExamDetails',
              component: ExamDetails,
            },
            {
              path: 'confirm',
              name: 'ExamConfirmation',
              component: ExamConfirmation
            },
            {
              path: 'done',
              name: 'ExamDone',
              component: ExamDone
            },
            {
              path: 'result',
              name: 'ExamResult',
              component: ExamResult
            },
            {
              path: ':questionNumber',
              name: 'ExamQuestion',
              component: ExamQuestion
            }
          ]
        },
        {
          path: "/c/specialists",
          name: "Specialists",
          component: Specialists,
          meta: {
            requiresAuth: true,
            title: i18n.t('All specialists')
          }
        },
        {
          path: "/c/specialist/:id(\\d+)",
          name: "SpecialistsDetails",
          component: Specialist,
          meta: {
            requiresAuth: true,
            title: i18n.t('specialist')
          }
        },
        {
          path: "/c/search",
          name: "Search",
          component: Search,
          meta: {
            requiresAuth: true,
            title: i18n.t('Search')
          }
        },
        {
          path: "/c/moods",
          name: "Moods",
          component: Moods,
          meta: {
            requiresAuth: true,
            title: i18n.t('Mood status')
          }
        },
        {
          path: "/c/profile",
          name: "profile",
          component: Profile,
          meta: {
            requiresAuth: true,
            title: i18n.t('Profile'),
          },
        },
        {
          path: "/c/profile/edit",
          name: "ProfileEdit",
          component: ProfileEdit,
          props: true,
          meta: {
            requiresAuth: true,
            title: i18n.t('Edit profile'),
          },
        },
		
        {
          path: "/c/profile/favorites",
          name: "ProfileFavorites",
          component: ProfileFavorites,
          props: true,
          meta: {
            requiresAuth: true,
            title: i18n.t('Favorites'),
          },
        },
        {
          path: "/c/profile/wallet",
          name: "ProfileWallet",
          component: ProfileWallet,
          props: true,
          meta: {
            requiresAuth: true,
            title: i18n.t('Wallet'),
          },
        },
        {
          path: "/c/profile/questions",
          name: "ProfileQusetions",
          component: ProfileQuestions,
          meta: {
            requiresAuth: true,
            title: i18n.t('Questions')
          },
        },
        {
          path: "/c/profile/setting",
          name: "ProfileSetting",
          component: ProfileSetting,
          meta: {
            requiresAuth: true,
            title: i18n.t('Setting')
          },
        },
        {
          path: "/c/explore",
          name: "Explore",
          component: Explore,
          meta: {
            requiresAuth: true,
            title: i18n.t('Explore')
          }
        },
        {
          path: "/c/podcasts",
          name: "Podcasts",
          component: Podcasts,
          meta: {
            requiresAuth: true,
            title: i18n.t('Podcasts')
          }
        },
        {
          path: "contents",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          meta: {
            requiresAuth: true,
            title: i18n.t('Posts'),
          },
          children: [
            {
              path: "",
              name: "Contents",
              component: Contents,
              meta: {
                requiresAuth: true,
                title: i18n.t('Posts'),
              },
            },
            {
              path: "post/:id(\\d+)",
              name: "PostDetails",
              component: Post_Details,
              props: true,
              meta: {
                requiresAuth: true,
                title: i18n.t('Post'),
              },
            },
            {
              path: "video/:id(\\d+)",
              name: "VideoDetails",
              component: Video_Details,
              props: true,
              meta: {
                requiresAuth: true,
                title: i18n.t('Video'),
              },
            },
            {
              path: "podcast/:id(\\d+)",
              name: "PodcastDetails",
              component: Podcast_Details,
              props: true,
              meta: {
                requiresAuth: true,
                title: i18n.t('Podcast'),
              },
            },
          ]
        },
        {
          path: "notifications",
          name: "Notifications",
          component: Notifications,
          props: true,
          meta: {
            requiresAuth: true,
            title: i18n.t('Notifications'),
          },
        },
        {
          path: "clinic",
          redirect: "/c/home",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          meta: {
            requiresAuth: true,
            title: i18n.t('Clinic'),
          },
          children: [
            {
              path: ":clinicId(\\d+)",
              name: "ClinicDetails",
              component: ClinicDetails,
              props: true,
              meta: {
                requiresAuth: true,
                title: i18n.t('Clinic'),
              },
            },
            {
              path: ":clinicId(\\d+)/contents",
              name: "ClinicContents",
              component: ClinicContents,
              props: true,
              meta: {
                requiresAuth: true,
                title: i18n.t('Posts'),
              },
            },
            {
              path: ":clinicId(\\d+)/questions",
              name: "ClinicQuestions",
              component: ClinicQuestions,
              props: true,
              meta: {
                requiresAuth: true,
                title: i18n.t('Questions'),
              },
            },
          ]
        },
      ],
    },
    {
      path: "/s/",
      redirect: "/s/home",
      name: "Doctor",
      meta: {
        requiresAuth: true,
        title: "",
      },
      component: TheContainerAppDoctor,
      children: [
        {
          path: "home",
          component: Doctor_Home,
          meta: {
            requiresAuth: true,
            title: "",
          },
        },
        {
          path: "explore",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          meta: {
            requiresAuth: true,
            title: i18n.t('Explore'),
          },
          children: [
            {
              path: "",
              name: "DoctorExplore",
              component: Doctor_Explore,
              meta: {
                requiresAuth: true,
                title: i18n.t('Explore'),
              },
            },

          ],
        },
        {
          path: "contents",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          meta: {
            requiresAuth: true,
            title: i18n.t('Posts'),
          },
          children: [
            {
              path: "",
              name: "DoctorContents",
              component: Doctor_Contents,
              meta: {
                requiresAuth: true,
                title: i18n.t('Posts'),
              },
            },
            {
              path: "post/:id(\\d+)",
              name: "DoctorPostDetails",
              component: Doctor_Post_Details,
              props: true,
              meta: {
                requiresAuth: true,
                title: i18n.t('Post'),
              },
            },
            {
              path: "post/:id(\\d+)/edit",
              name: "DoctorPostEdit",
              component: Doctor_Post_Edit,
              props: true,
              meta: {
                requiresAuth: true,
                title: i18n.t('Edit'),
              },
            },
            {
              path: "podcast/:id(\\d+)",
              name: "PodcastDetails",
              component: Doctor_Podcast_Details,
              props: true,
              meta: {
                requiresAuth: true,
                title: i18n.t('Podcast'),
              },
            },
            {
              path: "create",
              name: "DoctorCreateContent",
              component: Doctor_Create_Content,
              props: true,
              meta: {
                requiresAuth: true,
                title: i18n.t('New content'),
              },
            },
            {
              path: "video/:id(\\d+)",
              name: "DoctorVideoDetails",
              component: Doctor_Video_Details,
              props: true,
              meta: {
                requiresAuth: true,
                title: i18n.t('Video'),
              },
            },{
              path: "video/:id(\\d+)/edit",
              name: "DoctorVideoEdit",
              component: Doctor_Video_Edit,
              props: true,
              meta: {
                requiresAuth: true,
                title: i18n.t('Edit'),
              },
            },
          ]
        },
        {
          path: "specialist",
          redirect: "/s/home",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          meta: {
            requiresAuth: true,
            title: i18n.t('Specialist'),
          },
          children: [
            {
              path: ":id(\\d+)",
              name: "DoctorSpecialist",
              component: Doctor_Specialist,
              props: true,
              meta: {
                requiresAuth: true,
                title: i18n.t('Specialist'),
              },
            },
            {
              path: ":id(\\d+)/followers",
              name: "DoctorFollowers",
              component: Doctor_Followers,
              props: true,
              meta: {
                requiresAuth: true,
                title: i18n.t('Followers'),
              },
            }
          ]
        },
        {
          path: "questions",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          meta: {
            requiresAuth: true,
            title: i18n.t('Questions'),
          },
          children: [
            {
              path: "",
              name: "DoctorQuestions",
              component: Doctor_Questions,
              meta: {
                requiresAuth: true,
                title: i18n.t('Questions'),
              },
            },
            {
              path: ":questionId(\\d+)",
              name: "DoctorSingleQusetion",
              component: Doctor_SingleQusetion,
              props: true,
              meta: {
                requiresAuth: true,
                title: i18n.t('Questions'),
              },
            },
            {
              path: ":questionId(\\d+)/answer",
              name: "DoctorAddAnswer",
              component: Doctor_AddAnswer,
              props: true,
              meta: {
                requiresAuth: true,
                title: i18n.t('Questions'),
              },
            },
            {
              path: ":questionId(\\d+)/edit/:answerId(\\d+)",
              name: "DoctorEditAnswer",
              component: Doctor_EditAnswer,
              props: true,
              meta: {
                requiresAuth: true,
                title: i18n.t('Questions'),
              },
            },
          ]
        },
        {
          path: "profile",
          name: "SpecialistProfile",
          component: Doctor_Profile,
          props: true,
          meta: {
            requiresAuth: true,
            title: i18n.t('Profile'),
          },
        },
        {
          path: "profile/edit",
          name: "SpecialistProfileEdit",
          component: Doctor_ProfileEdit,
          props: true,
          meta: {
            requiresAuth: true,
            title: i18n.t('Edit profile'),
          },
        },
        {
          path: "profile/edit/call",
          name: "SpecialistProfileEditCallInfo",
          component: Doctor_ProfileEditCallInfo,
          props: true,
          meta: {
            requiresAuth: true,
            title: i18n.t('Edit call information'),
          },
        },
        {
          path: "map/:coordinates",
          name: "Doctor_Map",
          component: Doctor_Map,
          props: true,
          meta: {
            requiresAuth: true,
            title: i18n.t('Map'),
          },
        },
        {
          path: "profile/favorites",
          name: "SpecialistProfileFavorites",
          component: Doctor_ProfileFavorites,
          props: true,
          meta: {
            requiresAuth: true,
            title: i18n.t('Favorites'),
          },
        },
        {
          path: "exams",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          meta: {
            requiresAuth: true,
            title: i18n.t('Exams'),
          },
          children: [
            {
              path: "",
              name: "DoctorExams",
              component: Doctor_Exams,
              meta: {
                requiresAuth: true,
                title: i18n.t('Exams'),
              },
            },
            {
              path: "preview/:examId(\\d+)",
              name: "DoctorExamPreview",
              component: Doctor_ExamPreview,
              props: true,
              meta: {
                requiresAuth: true,
                title: i18n.t('Exam preview'),
              },
            },
            {
              path: "new",
              name: "DoctorExamNewRequest",
              component: Doctor_ExamNewRequest,
              props: true,
              meta: {
                requiresAuth: true,
                title: i18n.t('New request'),
              },
            },
            {
              path: "history",
              name: "DoctorExamHistory",
              component: Doctor_ExamHistory,
              props: true,
              meta: {
                requiresAuth: true,
                title: i18n.t('Exam history'),
              },
            },
            {
              path: "result/:examType/:examCode",
              name: "DoctorExamResult",
              component: Doctor_ExamResult,
              props: true,
              meta: {
                requiresAuth: true,
                title: i18n.t('Exam result'),
              },
            },
          ]
        },
        {
          path: "exercises",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          meta: {
            requiresAuth: true,
            title: i18n.t('Exercises'),
          },
          children: [
            {
              path: "",
              name: "DoctorExercises",
              component: Doctor_Exercises,
              meta: {
                requiresAuth: true,
                title: i18n.t('Exercises'),
              },
            },
            {
              path: "edit/:exerciseId(\\d+)",
              name: "DoctorEditExercise",
              component: Doctor_EditExercise,
              props: true,
              meta: {
                requiresAuth: true,
                title: i18n.t('Edit Exercise'),
              },
            },
            {
              path: "create",
              name: "DoctorCreateExercise",
              component: Doctor_CreateExercise,
              props: true,
              meta: {
                requiresAuth: true,
                title: i18n.t('Create Exercise'),
              },
            },
            {
              path: "new",
              name: "DoctorExerciseNewRequest",
              component: Doctor_ExerciseNewRequest,
              props: true,
              meta: {
                requiresAuth: true,
                title: i18n.t('New request'),
              },
            },
            {
              path: "history",
              name: "DoctorExerciseHistory",
              component: Doctor_ExerciseHistory,
              props: true,
              meta: {
                requiresAuth: true,
                title: i18n.t('Exercise history'),
              },
            },
            {
              path: "result/:exerciseId(\\d+)",
              name: "DoctorExerciseResult",
              component: Doctor_ExerciseResult,
              props: true,
              meta: {
                requiresAuth: true,
                title: i18n.t('Exercise result'),
              },
            },
          ],
        },
        {
          path: "moods/:username",
          name: "DoctorMoodsOfClient",
          component: Doctor_MoodsOfClient,
          props: true,
          meta: {
            requiresAuth: true,
            title: i18n.t('Mood status'),
          },
        },
        {
          path: "notifications",
          name: "DoctorNotifications",
          component: Doctor_Notifications,
          props: true,
          meta: {
            requiresAuth: true,
            title: i18n.t('Notifications'),
          },
        },
        {
          path: "clinic",
          redirect: "/s/profile",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          meta: {
            requiresAuth: true,
            title: i18n.t('Clinic'),
          },
          children: [
            {
              path: ":clinicId(\\d+)/owner",
              name: "DoctorClinicOwner",
              component: Doctor_ClinicOwner,
              props: true,
              meta: {
                requiresAuth: true,
                title: i18n.t('My clinic'),
              },
            },
            {
              path: ":clinicId(\\d+)/members",
              name: "DoctorClinicOwnerMembers",
              component: Doctor_ClinicOwner_Members,
              props: true,
              meta: {
                requiresAuth: true,
                title: i18n.t('Members'),
              },
            },
            {
              path: ":clinicId(\\d+)/edit",
              name: "DoctorClinicOwnerEdit",
              component: Doctor_ClinicOwner_Edit,
              props: true,
              meta: {
                requiresAuth: true,
                title: i18n.t('Edit') + ' ' + i18n.t('Clinic'),
              },
            },
            {
              path: ":clinicId(\\d+)/report",
              component: {
                render(c) {
                  return c("router-view");
                },
              },
              meta: {
                requiresAuth: true,
                title: i18n.t('Operation report'),
              },
              children: [
                {
                  path: "financial",
                  name: "DoctorClinicOwnerFinancial",
                  component: Doctor_ClinicOwner_Financial,
                  props: true,
                  meta: {
                    requiresAuth: true,
                    title: i18n.t('Operation report') + ' '+ i18n.t('Financial'),
                  },
                },
                {
                  path: "contents",
                  name: "DoctorClinicOwnerContents",
                  component: Doctor_ClinicOwner_Contents,
                  props: true,
                  meta: {
                    requiresAuth: true,
                    title: i18n.t('Operation report') + ' '+ i18n.t('Content'),
                  },
                },
                {
                  path: "exams",
                  name: "DoctorClinicOwnerExams",
                  component: Doctor_ClinicOwner_Exams,
                  props: true,
                  meta: {
                    requiresAuth: true,
                    title: i18n.t('Operation report') + ' '+ i18n.t('Exams'),
                  },
                },
                {
                  path: "exercises",
                  name: "DoctorClinicOwnerExercises",
                  component: Doctor_ClinicOwner_Exercises,
                  props: true,
                  meta: {
                    requiresAuth: true,
                    title: i18n.t('Operation report') + ' '+ i18n.t('Exercises'),
                  },
                },
                {
                  path: "questions",
                  name: "DoctorClinicOwnerQuestions",
                  component: Doctor_ClinicOwner_Questions,
                  props: true,
                  meta: {
                    requiresAuth: true,
                    title: i18n.t('Operation report') + ' '+ i18n.t('Questions and answers'),
                  },
                },
                {
                  path: "schedules",
                  name: "DoctorClinicOwnerSchedules",
                  component: Doctor_ClinicOwner_Schedules,
                  props: true,
                  meta: {
                    requiresAuth: true,
                    title: i18n.t('Operation report') + ' '+ i18n.t('Schedule a patient'),
                  },
                },
              ]
            },
            {
              path: ":clinicId(\\d+)/member",
              name: "DoctorClinicMember",
              component: Doctor_ClinicMember,
              props: true,
              meta: {
                requiresAuth: true,
                title: i18n.t('Clinic'),
              },
            },
            {
              path: ":clinicId(\\d+)",
              name: "DoctorClinicDetails",
              component: Doctor_ClinicDetails,
              props: true,
              meta: {
                requiresAuth: true,
                title: i18n.t('Clinic'),
              },
            },
            {
              path: ":clinicId(\\d+)/contents",
              name: "DoctorClinicContents",
              component: Doctor_ClinicContents,
              props: true,
              meta: {
                requiresAuth: true,
                title: i18n.t('Posts'),
              },
            },
            {
              path: ":clinicId(\\d+)/questions",
              name: "DoctorClinicQuestions",
              component: Doctor_ClinicQuestions,
              props: true,
              meta: {
                requiresAuth: true,
                title: i18n.t('Questions'),
              },
            },
          ]
        },
        {
          path: "financial",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          meta: {
            requiresAuth: true,
            title: i18n.t('Financial'),
          },
          children: [
            {
              path: "",
              name: "DoctorFinancial",
              component: Doctor_Financial,
              props: true,
              meta: {
                requiresAuth: true,
                title: i18n.t('Financial'),
              },
            },
            {
              path: "transactions",
              name: "DoctorTransactions",
              component: Doctor_Transactions,
              props: true,
              meta: {
                requiresAuth: true,
                title: i18n.t('Transactions'),
              },
            },
            {
              path: "settlements",
              name: "DoctorSettlements",
              component: Doctor_Settlements,
              props: true,
              meta: {
                requiresAuth: true,
                title: i18n.t('Settlements'),
              },
            },
            {
              path: "settlements/:settlementId(\\d+)",
              name: "DoctorSettlementDetails",
              component: Doctor_SettlementDetails,
              props: true,
              meta: {
                requiresAuth: true,
                title: i18n.t('Settlement details'),
              },
            },
          ]
        },
        {
          path: "schedule",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          meta: {
            requiresAuth: true,
            title: i18n.t('SCHEDULE'),
          },
          children: [
            {
              path: "",
              name: "DoctorSchedule",
              component: Doctor_Schedule,
              meta: {
                requiresAuth: true,
                title: i18n.t('SCHEDULE'),
              },
            },
          ]
        }
      ],
    },
    {
      // matches everything else
      path: "/:pathMatch(.*)*",
      name: "notFound",
      component: Page404,
      meta: { title: "404" },
    },
    {
      // matches everything else
      path: "*",
      name: "notFounded",
      component: Page404,
      meta: { title: "404" },
    },
  ],
});

const DEFAULT_TITLE = "مایندولوژی";
router.afterEach((to, from) => {
  // Use next tick to handle router history correctly
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  Vue.nextTick(() => {
    if (to.meta.title != "") {
      document.title = to.meta.title + " | مایندولوژی" || DEFAULT_TITLE;
    } else {
      document.title = "مایندولوژی" || DEFAULT_TITLE;
    }
  });
});

const waitForStorageToBeReady = async (to, from, next) => {
  
  await store.restored
  if (from.path.startsWith('/c/') && to.path === '/verify-payment' ) {
    next( {path: '/c/profile'} );
  }
  else if (to.meta.requiresAuth && !store.getters.isLoggedIn)
    next({ name: "Auth" });
  else if (store.getters.clientType == "CLIENT" && to.path.startsWith('/s/'))
    next({ name: "User" })
  else if (store.getters.clientType == "DOCTOR" && to.path.startsWith('/c/'))
    next({ name: "Doctor" })
  else
    next();
}
router.beforeEach(waitForStorageToBeReady)

export default router;
