<template>
  <v-app>
    <PWAPrompt/>
    <notifications position="bottom right"/>
    <router-view></router-view>
    <div class="loading" v-if="isLoading">
      <div class="block">
        <section><span class="loader"></span></section>
      </div>
    </div>
  </v-app>
</template>

<script>
import {mapState} from "vuex";
import apiService from "@/http/ApiService";
import PWAPrompt from "@/components/PWAPrompt.vue";

// import {getMessaging, getToken, onMessage} from "firebase/messaging";
// import {app} from "@/plugins/firebase";
import {getDeviceToken, onMessageListener} from "./plugins/firebase";

export default {
  name: "App",
  data() {
    return {
      installDialog: false,
      installDialogTimeout: 10000,
      deferredPrompt: null,

      messaging: null,
      clientToken: null,
      statusMessage: null,
    };
  },
  components: {
    PWAPrompt,
  },
  computed: {
    ...mapState(["isLoading"]),
  },
  mounted() {
    onMessageListener().then((payload) => {
      this.$toast.info(
          payload.data.title + "\n" + payload.data.body
      );
    });
    getDeviceToken().then((token) => {
      this.setSubscribedToken(token);
      this.sendTokenToServer(token);
    });
  },
  methods: {
    getDoctorInfoByMyMobile() {
      apiService.getDoctorInfoByMyMobile()
          .then((response) => {
            var doctor = response.data;
            this.$store.commit(
                "update_fullName",
                doctor.firstname + " " + doctor.lastname
            );
            this.$store.commit("update_doctorId", doctor.id);
            this.$store.commit("update_clinicId", doctor.clinic.id);
          })
          .catch((err) => {
            console.error(err);
          });
    },
    getUserInformationByPhoneNumber() {
      apiService.getUserInformationByPhoneNumber(this.$store.getters.mobileNumber)
          .then((response) => {
            var client = response.data;
            this.$store.commit(
                "update_fullName",
                client.firstName + " " + client.lastName
            );
            this.$store.commit("update_clientId", client.id);
          })
          .catch((err) => {
            console.error(err);
          });
    },
    getNotificationCount() {
      apiService.getNotificationCount()
          .then((response) => {
            this.$store.commit("update_notificationCount", response.data.count);
          })
          .catch((err) => {
            console.error(err);
          });
    },
    isSubscribed() {
      let ct = this.$store.getters.firebaseToken;
      return !!ct;
    },
    setSubscribedToken(data) {
      this.clientToken = data;
      this.$store.commit("update_firebaseToken", this.clientToken);
    },
    unsetSubscribedToken() {
      this.clientToken = null;
      this.$store.commit("update_firebaseToken", "");
    },
    async getSubscribedToken() {
      let ct = this.$store.getters.firebaseToken;
      if (ct) return ct;
      else return null;
    },
    sendTokenToServer(token) {
      let dataObj = {token};
      if (this.clientToken && this.clientToken.uuid) {
        dataObj = {uuid: this.clientToken.uuid, token};
      }
      if (this.$store.getters.isLoggedIn && this.$store.getters.clientType == "CLIENT") {
        return apiService.saveFcmTokenClient(token);
      } else if (this.$store.getters.isLoggedIn && this.$store.getters.clientType == "DOCTOR") {
        return apiService.saveFcmTokenDoctor(token);
      }
    },
  },
  watch: {
    $route: function (to, from) {

      //TODO Chikar konim?
      // if (this.$store.getters.isLoggedIn) {
      //   this.getNotificationCount();
      // }

      if (to.path == "/s/home") {
        this.getNotificationCount();
        this.getDoctorInfoByMyMobile();
      }
      if (to.path === "/c/home") {
        this.getNotificationCount();
        this.getUserInformationByPhoneNumber();
      }
    },
    "$store.getters.locale": function () {
      if (this.$store.getters.locale) {
        this.$i18n.locale = this.$store.getters.locale;
      } else {
        this.$store.commit("update_locale", this.$i18n.locale);
      }

      if (this.$store.getters.locale == "fa") this.$vuetify.rtl = true;
      else this.$vuetify.rtl = false;
    },
  },
  beforeMount() {
    if (
        this.$store.getters.isLoggedIn &&
        this.$store.getters.clientType == "DOCTOR"
    ) {
      this.getDoctorInfoByMyMobile();
    }

    if (
        this.$store.getters.isLoggedIn &&
        this.$store.getters.clientType == "CLIENT"
    ) {
      this.getUserInformationByPhoneNumber();
    }
  },
};
</script>
<style>
.loader {
  width: 48px;
  height: 48px;
  border: 3px solid #fff;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  -webkit-animation: rotation 1s linear infinite;
  animation: rotation 1s linear infinite;
}

.loader:after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-bottom-color: #20b1ea;
}

@-webkit-keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading {
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.25);
  z-index: 2000000000;
}

.block {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 2rem;
  width: 2rem;
  transform: translateX(-50%) translateY(-50%);
}
</style>
