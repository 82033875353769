import http from 'axios'
import axios from "@/http/httpApplication.js";
import store from "@/store/store.js"

axios.defaults.headers.common = {
    'Authorization': `${store.getters.token}`,
    'Access-Control-Allow-Origin': `*`
};

export default {

    upload(type, file, filename, onUploadProgress) {

        const url = `/file/single-file-upload?uploadType=${type}`;

        let formData = new FormData();

        formData.append("file", file, filename);

        return axios.post(url, formData, {
            headers: {
                "content-type": "multipart/form-data"
            },
            onUploadProgress
        });
    },


    getPublicIPAddress() {
        const url = process.env.VUE_APP_IP_URL;
        return http({
            method: 'get',
            url: url,
            responseType: 'text',
            headers: {"Access-Control-Allow-Origin": "*"}
        })
    },

    //-----------------------------------------------------------------------------------------
    // Base Information
    //-----------------------------------------------------------------------------------------
    saveFcmTokenDoctor(token) {
        return axios.post(`/doctor/notification-token?token=${token}&mobileNumber=${encodeURIComponent(store.getters.mobileNumber)}`);
    },

    saveFcmTokenClient(token) {
        return axios.post(`/client/user/notification-token?token=${token}&mobileNumber=${encodeURIComponent(store.getters.mobileNumber)}`);
    },

    getEducations() {
        const url = `/base/information/item/education`;
        return axios.get(url);
    },

    getSpeciality() {
        const url = `/base/information/item/specialty`;
        return axios.get(url);
    },

    getActivities() {
        const url = `/base/information/item/activity`;
        return axios.get(url);
    },

    getExerciseType() {
        const url = `/base/information/item/exercise-type`;
        return axios.get(url);
    },


    //-----------------------------------------------------------------------------------------
    // Ex requests
    //-----------------------------------------------------------------------------------------
    addNewExRequest(formData, title, comment, type) {

        const query = {"title": title, "comment": comment, "type": type}
        const params = JSON.stringify(query);

        formData.append("exRequest", params);

        const url = `/ex/new`;

        return axios.post(url, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        })
    },

    //-----------------------------------------------------------------------------------------
    // Follower
    //-----------------------------------------------------------------------------------------

    getAllFollowers(page, size) {
        const url = `/doctor/follow-list/${store.getters.doctorId}?page=${page}&size=${size}&sort=createDate,desc`;
        return axios.get(url);
    },

    querySearchFollowers(searchText) {
        const url = `/doctor/search-follow-list?doctorId=${store.getters.doctorId}&searchParam=${searchText}`;
        return axios.post(url);
    },

    //-----------------------------------------------------------------------------------------
    // Questions
    //-----------------------------------------------------------------------------------------
    getAllQuestions() {
        const url = `/user-question/accepted-categorized-list`;
        return axios.get(url);
    },

    getQuestionsByGroupId(groupId, page, size) {
        const url = `/user-question/list-by-group/${groupId}?page=${page}&size=${size}`;
        return axios.get(url);
    },

    getUserQuestionPaging(clientUserId, page, size) {
        const url = `/user-question/list-by-user/${clientUserId}?page=${page}&size=${size}&sort=createDate,desc`;
        return axios.get(url);
    },

    getQuestionDetails(questionId) {
        const url = `/user-question/detail/${questionId}`;
        return axios.get(url);
    },

    doctorAnswerQuestionsList(id) {
        const url = `/doctor/answer-question-list/${id}`;
        return axios.get(url);
    },

    doctorPublishedContentList(doctorId) {
        const url = `/doctor/published-contents/${doctorId}`;
        return axios.get(url);
    },

    setViewQuestion(userQuestionId, userType) {
        const url = `/user-question/view?userQuestionId=${userQuestionId}&userType=${userType}`;
        return axios.post(url);
    },

    getAnswerGuid() {
        const url = `/doctor/guide`;
        return axios.get(url);
    },

    getAskQuestionGuid() {
        const url = `/user-question/guide`;
        return axios.get(url);
    },

    answerToQuestion(answerUserQuestionDTO) {
        const url = `/user-question/answer`;
        return axios.post(url, answerUserQuestionDTO);
    },

    askQuestion(userQuestionDTO) {
        const url = `/user-question/add`;
        return axios.post(url, userQuestionDTO);
    },

    editQuestion(userQuestionDTO) {
        const url = `/user-question`;
        return axios.put(url, userQuestionDTO);
    },

    editAnswerToQuestion(answerUserQuestionDTO) {
        const url = `/user-question/edit-answer`;
        return axios.post(url, answerUserQuestionDTO);
    },

    deleteAnswer(answerId) {
        const url = `/user-question/delete-answer/${answerId}`;
        return axios.delete(url);
    },

    deleteQuestion(questionId) {
        const url = `/user-question/delete-question/${questionId}`;
        return axios.delete(url);
    },

    likeAnswer(answerId, userType) {
        const url = `/user-question/like/answer?answerId=${answerId}&userType=${userType}`;
        return axios.post(url);
    },

    unlikeAnswer(answerId, userType) {
        const url = `/user-question/unlike/answer?answerId=${answerId}&userType=${userType}`;
        return axios.post(url);
    },


    likeQuestion(questionId, userType) {
        const url = `/user-question/like?questionId=${questionId}&userType=${userType}`;
        return axios.post(url);
    },

    unlikeQuestion(questionId, userType) {
        const url = `/user-question/like?questionId=${questionId}&userType=${userType}`;
        return axios.post(url);
    },

    //-----------------------------------------------------------------------------------------
    // Payment
    //-----------------------------------------------------------------------------------------
    getPaymentRedirectPage(paymentDTO) {
        const url = `/payment/initiate`;
        return axios.post(url, paymentDTO);
    },

    paymentWithWallet(paymentDTO) {
        const url = `/payment/wallet`;
        return axios.post(url, paymentDTO);
    },

    verifyPayment(queryParams) {
        const url = `/payment/verify`;
        return axios.post(url, queryParams);
    },

    //-----------------------------------------------------------------------------------------
    // Commons
    //-----------------------------------------------------------------------------------------
    getNotificationCount() {
        const url = `/message/unread/count`;
        return axios.get(url);
    },

    getNotifications(page, size, sort) {
        const url = `/message?page=${page}&size=${size}&sort=${sort}`;
        return axios.get(url);
    },
    NotificationsMarkAsReadAll() {
        const url = `/message/read/all`;
        return axios.post(url);
    },

    getDoctorAccountBalance() {
        const url = `/wallet/doctor-wallet-balance`;
        return axios.get(url);
    },

    getClientAccountBalance() {
        const url = `/wallet/client-wallet-balance`;
        return axios.get(url);
    },

    getDoctorInfoByMyMobile() {
        const url = `/doctor/info/${encodeURIComponent(store.getters.mobileNumber)}`;
        return axios.get(url);
    },

    getDoctorInfoByMobile(mobile) {
        const url = `/doctor/info/${encodeURIComponent(mobile)}`;
        return axios.get(url);
    },

    getDoctorInfoById(id) {
        const url = `/doctor/info-by-id/${id}`;
        return axios.get(url);
    },

    UpdateDoctor(DoctorDTO) {
        const url = `/doctor/update`;
        return axios.post(url, DoctorDTO);
    },

    UpdateClientUserDTO(ClientUserDTO) {
        const url = `/client/user/update`;
        return axios.post(url, ClientUserDTO);
    },

    updateDoctorContactInformation(DoctorContactInformationDTO) {
        const url = `/doctor/contact-information`;
        return axios.post(url, DoctorContactInformationDTO);
    },

    updateConsultant(consultantAmount, paymentType) {
        const url = `/doctor/scheduling/config/consultant-payment-type?consultantAmount=${consultantAmount}&paymentType=${paymentType}`;
        return axios.post(url);
    },

    addDoctorIban(iban) {
        const url = `/wallet/add-iban/${iban}`;
        return axios.get(url);
    },

    settlementRequest() {
        console.log('why?')
        const url = `/wallet/doctor-settlement`;
        return axios.get(url);
    },

    getSettlements(page, size, sort) {
        const url = `/settlement/list-per-doctor/${encodeURIComponent(store.getters.mobileNumber)}?page=${page}&size=${size}&sort=${sort}`;
        return axios.get(url);
    },

    getSettlementsDetails(settlementId) {
        const url = `/wallet/settlement-detail/${settlementId}/per-doctor/${encodeURIComponent(store.getters.mobileNumber)}`;
        return axios.get(url);
    },

    setDoctorFollow(doctorId, userId) {
        const url = `/doctor/follow?doctorId=${doctorId}&userId=${userId}`;
        return axios.post(url);
    },

    setDoctorUnFollow(doctorId, userId) {
        const url = `/doctor/unfollow?doctorId=${doctorId}&userId=${userId}`;
        return axios.post(url);
    },

    //-----------------------------------------------------------------------------------------
    // Clinic Reports
    //-----------------------------------------------------------------------------------------
    getFinancialSumReport(startDate, endDate, clinicId) {
        const url = `/clinic/report/sum-member-income-between/${startDate}/and/${endDate}/by/${clinicId}`;
        return axios.get(url);
    },

    getExamSumReport(startDate, endDate, clinicId) {
        const url = `/clinic/report/sum-exam-between/${startDate}/and/${endDate}/by/${clinicId}`;
        return axios.get(url);
    },

    getExerciseSumReport(startDate, endDate, clinicId) {
        const url = `/clinic/report/sum-exercise-between/${startDate}/and/${endDate}/by/${clinicId}`;
        return axios.get(url);
    },

    getQuestionSumReport(startDate, endDate, clinicId) {
        const url = `/clinic/report/sum-answered-question-between/${startDate}/and/${endDate}/by/${clinicId}`;
        return axios.get(url);
    },

    getPostSumReport(startDate, endDate, clinicId) {
        const url = `/clinic/report/sum-published-post-between/${startDate}/and/${endDate}/by/${clinicId}`;
        return axios.get(url);
    },

    getPodcastSumReport(startDate, endDate, clinicId) {
        const url = `/clinic/report/sum-published-podcast-between/${startDate}/and/${endDate}/by/${clinicId}`;
        return axios.get(url);
    },

    getVideoSumReport(startDate, endDate, clinicId) {
        const url = `/clinic/report/sum-published-video-between/${startDate}/and/${endDate}/by/${clinicId}`;
        return axios.get(url);
    },

    getAppointmentsReport(startDate, endDate, clinicId) {
        const url = `/clinic/report/appointments/${clinicId}/${startDate}/${endDate}`;
        return axios.get(url);
    },

    //-----------------------------------------------------------------------------------------
    // Exam
    //-----------------------------------------------------------------------------------------
    getExamAssignedTopList(limit) {
        const url = `/exam-assign/top?limit=${limit}`;
        return axios.get(url);
    },

    getCategoryExamList(page, size) {
        const url = `/exam-category?page=${page}&size=${size}`;
        return axios.get(url);
    },

    getAllExams(page, size) {
        const url = `/exam/private?page=${page}&size=${size}`;
        return axios.get(url);
    },

    getExamsByGroupId(category_id, page, size) {
        const url = `/exam/private?category_id=${category_id}&page=${page}&size=${size}`;
        return axios.get(url);
    },

    examAddToFavorite(examFavoriteDTO) {
        const url = `/exam-favorite`;
        return axios.post(url, examFavoriteDTO);
    },

    examDeleteFromFavorite(id) {
        const url = `/exam-favorite/${id}`;
        return axios.delete(url);
    },

    examDeleteFromFavoriteByAssignId(favoriteId, examAssignId) {
        const url = `/exam-favorite/remove/${favoriteId}/exam-assign/${examAssignId}`;
        return axios.delete(url);
    },

    examAssignToUser(examAssignDTO) {
        const url = `/exam-assign`;
        return axios.post(url, examAssignDTO);
    },

    examPreview(id) {
        const url = `/exam/${id}/full`;
        return axios.get(url);
    },

    getExamAssignedHistory(page, size, status) {
        const url = `/exam-assign/history?page=${page}&size=${size}&status=${status}`;
        return axios.get(url);
    },

    getExamDetails(code) {
        const url = `/exam/todo/${code}`;
        return axios.get(url);
    },

    getExamTodoList(code) {
        const url = `/exam/todo/${code}/list`;
        return axios.get(url);
    },

    getPublicExamResult(code) {
        const url = `/exam-result/code/${code}`;
        return axios.get(url);
    },

    getPrivateExamResult(code) {
        const url = `/exam-result/code/${code}/all`;
        return axios.get(url);
    },

    //-----------------------------------------------------------------------------------------
    // Exercise
    //-----------------------------------------------------------------------------------------
    getExerciseAssignedTopList(limit) {
        const url = `/exercise-assign/top?limit=${limit}`;
        return axios.get(url);
    },

    getCategoryExerciseList(page, size) {
        const url = `/exercise-category?page=${page}&size=${size}`;
        return axios.get(url);
    },

    getAllExercises(page, size) {
        const url = `/exercise/me?page=${page}&size=${size}`;
        return axios.get(url);
    },

    getExercisesByGroupId(category_id, page, size) {
        const url = `/exercise/me?category_id=${category_id}&page=${page}&size=${size}`;
        return axios.get(url);
    },

    exerciseAddToFavorite(exerciseFavoriteDTO) {
        const url = `/exercise-favorite`;
        return axios.post(url, exerciseFavoriteDTO);
    },

    exerciseDeleteFromFavorite(id) {
        const url = `/exercise-favorite/${id}`;
        return axios.delete(url);
    },

    exerciseAssignToUser(exerciseAssignDTO) {
        const url = `/exercise-assign`;
        return axios.post(url, exerciseAssignDTO);
    },

    exercisePreview(id) {
        const url = `/exercise/${id}`;
        return axios.get(url);
    },

    createExercise(exerciseDTO) {
        const url = `/exercise`;
        return axios.post(url, null, {
            params: exerciseDTO,
            headers: {'content-type': 'application/json;charset=UTF-8'}
        });
    },

    editExercise(exerciseDTO) {
        const url = `/exercise`;
        return axios.put(url, exerciseDTO);
    },

    deleteExercise(id) {
        const url = `/exercise/${id}`;
        return axios.delete(url);
    },

    getExerciseAssignedHistory(page, size, status) {
        const url = `/exercise-assign/history?page=${page}&size=${size}&status=${status}`;
        return axios.get(url);
    },

    getExerciseAnswerByUsername(exerciseId, username) {
        const url = `/exercise-detail/client-user-answer?exerciseId=${exerciseId}&username=${encodeURIComponent(username)}`;
        return axios.post(url);
    },

    getExerciseDetails(id) {
        const url = `/exercise/user-exercise/${id}`;
        return axios.get(url);
    },

    postExerciseAnswer(exerciseDetailDTO) {
        const url = `/exercise-detail`;
        return axios.post(url, exerciseDetailDTO);
    },

    deleteExerciseAnswerById(id) {
        const url = `/exercise-detail/${id}`;
        return axios.delete(url);
    },

    //-----------------------------------------------------------------------------------------
    // Clinic
    //-----------------------------------------------------------------------------------------
    getClinicDetails(clinicId) {
        const url = `/clinic/${clinicId}`;
        return axios.get(url);
    },

    getClinicContents(clinicId, page, size) {
        const url = `/clinic/content/${clinicId}?page=${page}&size=${size}`;
        return axios.get(url);
    },

    getClinicAnswerQuestion(clinicId, page, size) {
        const url = `/clinic/report/member/${clinicId}/answered-question?page=${page}&size=${size}`;
        return axios.get(url);
    },

    getDoctorRoles(page, size) {
        const url = `/role?page=${page}&size=${size}`;
        return axios.get(url);
    },

    getClinicMemberWithRole(clinicId) {
        const url = `/clinic/report/member-by-clinic-id/${clinicId}`;
        return axios.get(url);
    },

    getFeeRoleConfig(id) {
        const url = `/fee-role-config/${id}`;
        return axios.get(url);
    },

    addClinicMember(ClinicMemberDTO) {
        const url = `/clinic/add-member`;
        return axios.post(url, ClinicMemberDTO);
    },

    updateClinicInformation(entity) {
        const url = `/clinic`;
        return axios.post(url, entity);
    },


    //-----------------------------------------------------------------------------------------
    // Moods
    //-----------------------------------------------------------------------------------------
    getClientUserLatestMood(username) {
        const url = `/mood/latest/${encodeURIComponent(username)}`;
        return axios.get(url);
    },

    getClientUserMoodsByUsername(username, startDate, endDate) {
        const url = `/mood/statistics/by-username?startDate=${startDate}&endDate=${endDate}&username=${encodeURIComponent(username)}`;
        return axios.post(url);
    },

    getClientUserMoods(startDate, endDate) {
        const url = `/mood/statistics?startDate=${startDate}&endDate=${endDate}`;
        return axios.post(url);
    },

    addMood(moodDTO) {
        const url = `/mood/add`;
        return axios.post(url, moodDTO);
    },

    deleteMood(moodId) {
        const url = `/mood/delete/${moodId}`;
        return axios.delete(url);
    },


    //-----------------------------------------------------------------------------------------
    // Other
    //-----------------------------------------------------------------------------------------

    getPosts() {
        const url = `/post/list-all`;
        return axios.get(url);
    },


    getPodcasts() {
        const url = `/podcast/list-all`;
        return axios.get(url);
    },

    getVideos() {
        const url = `/video/list-all`;
        return axios.get(url);
    },

    getLatestPosts(page, size) {
        const url = `/explore/list-page?page=${page}&size=${size}`;
        return axios.get(url);
    },

    //-----------------------------------------------------
    //Single Page Api
    //-----------------------------------------------------

    singlePost(id) {
        const url = `/post/info?postId=${id}`;
        return axios.get(url);
    },

    singlePodcast(id) {
        const url = `/podcast/info?podcastId=${id}`;
        return axios.get(url);
    },

    singleVideo(id) {
        const url = `/video/info?videoId=${id}`;
        return axios.get(url);
    },

    singleQuestion(id) {
        const url = `/question/detail/${id}`;
        return axios.get(url);
    },
    addPodcastComment(Data) {
        const url = `/podcast/add/comment`;
        return axios.post(url, Data);
    },

    //------------------------------POST------------------------------------

    getPostspublishPaging(page, pageSize) {
        const url = `/post/list-published-page?page=${page}&size=${pageSize}`;
        return axios.get(url);
    },
    ExplorePosts(page, size) {
        const url = `/explore/list-page?page=${page}&size=${size}`;
        return axios.get(url);
    },
    getAllPostsPaging(Id, page, pageSize) {
        const url = `/explore/list-by-group-id-page?groupId=${Id}&page=${page}&size=${pageSize}`;
        return axios.get(url)
    },
    getAllPosts() {
        const url = `/explore/list`;
        return axios.get(url);
    },
    getPostById(id) {
        const url = `/post/info?postId=${id}`;
        return axios.get(url);
    },
    getPostCommentsById(id) {
        const url = `/post/comment-list?postId=${id}`;
        return axios.get(url);
    },
    addPostComment(Data) {
        const url = `/post/add/comment`;
        return axios.post(url, Data);
    },
    setViewPost(id) {
        const url = `/post/view?postId=${id}`;
        return axios.post(url);
    },
    likePost(id) {
        const url = `/post/like?postId=${id}`;
        return axios.post(url);
    },
    unLikePost(id) {
        const url = `/post/unlike?postId=${id}`;
        return axios.post(url);
    },
    deletePostComment(id) {
        const url = `/post/comment/delete?commentId=${id}`;
        return axios.post(url);
    },
    bookmarkPost(id) {
        const url = `/post/bookmark`;
        return axios.post(url, id);
    },

    deletebookmarkPost(id) {
        const url = `/post/bookmark/delete`;
        return axios.post(url, id);
    },

    getBookmarkedPost() {
        const url = `/post/bookmark/post/list`;
        return axios.get(url);
    },

    addPost(Data) {
        const url = `/post/add`;
        return axios.post(url, Data);
    },

    updatePost(Data) {
        const url = `/post/update`;
        return axios.put(url, Data);
    },

    deletePost(postId) {
        const url = `/post/delete/${postId}`;
        return axios.delete(url);
    },

    //------------------------------PODCAST---------------------------------

    getPodcastspublishPaging(page, pageSize) {
        const url = `/podcast/list-published-page?page=${page}&size=${pageSize}`;
        return axios.get(url);
    },
    getPodcastsByGroupId(id) {
        const url = `/podcast/list/by-group-id?groupId=${id}`;
        return axios.get(url);
    },
    getPodcastById(id) {
        const url = `/podcast/info?podcastId=${id}`;
        return axios.get(url);
    },
    setViewPodcast(id) {
        const url = `/podcast/view?podcastId=${id}`;
        return axios.post(url);
    },
    getPodcastCommentsById(id) {
        const url = `/podcast/comment-list?podcastId=${id}`;
        return axios.get(url);
    },
    likePodcast(id) {
        const url = `/podcast/like?podcastId=${id}`;
        return axios.post(url);
    },
    unLikePodcast(id) {
        const url = `/podcast/unlike?podcastId=${id}`;
        return axios.post(url);
    },
    bookmarkPodcast(id) {
        const url = `/podcast/bookmark`;
        return axios.post(url, id);
    },
    deletebookmarkPodcast(id) {
        const url = `/podcast/bookmark/delete `;
        return axios.post(url, id);
    },
    deletePodcastComment(id) {
        const url = `/podcast/comment/delete/${id}`;
        return axios.delete(url);
    },
    getBookmarkedPodcast() {
        const url = `/podcast/bookmark/podcast/list`;
        return axios.get(url);
    },
    //------------------------------VIDEO-----------------------------------

    getPublishedVideos(page, pageSize) {
        const url = `/video/list-published-page?page=${page}&size=${pageSize}`;
        return axios.get(url);
    },
    getVideoById(id) {
        const url = `/video/info?videoId=${id}`;
        return axios.get(url);
    },
    getVideoCommentsById(id) {
        const url = `/video/comment-list?videoId=${id}`;
        return axios.get(url);
    },
    addVideoComment(Data) {
        const url = `/video/add/comment`;
        return axios.post(url, Data)
    },
    setViewVideo(id) {
        const url = `/video/view?videoId=${id}`;
        return axios.post(url);
    },
    likeVideo(id) {
        const url = `/video/like?videoId=${id}`;
        return axios.post(url);
    },
    unLikeVideo(id) {
        const url = `/video/unlike?videoId=${id}`;
        return axios.post(url);
    },
    bookmarkvideo(id) {
        const url = `/video/bookmark`;
        return axios.post(url, id);
    },
    deleteBokmarkvideo(id) {
        const url = `/video/bookmark/delete`;
        return axios.post(url, id);
    },
    getBookmarkedVideo() {
        const url = `/video/bookmark/video/list`;
        return axios.get(url);
    },
    addVideo(Data) {
        const url = `/video/add`;
        return axios.post(url, Data)
    },
    updateVideo(Data) {
        const url = `/video/update`;
        return axios.put(url, Data);
    },
    deleteVideo(videoId) {
        const url = `/video/delete/${videoId}`;
        return axios.delete(url);
    },
    //------------------------------DOCTORS-----------------------------------
    LatestDoctors() {
        const url = `/doctor/latest-signup`;
        return axios.get(url);
    },
    getBaseInformationChilds(code) {
        const url = `/base/information/item/${code}`;
        return axios.get(url);
    },

    gatLatestExamExerciseActive(etype, page, size) {
        const url = `exam-exercise/me-active?etype=${etype}&page=${page}&size=${size}`;
        return axios.get(url);
    },

    gatExamExercise(etype, page, size) {
        const url = `exam-exercise/me?etype=${etype}&page=${page}&size=${size}`;
        return axios.get(url);
    },

    getDoctorsById(id, page, size) {
        const url = `doctor/list-by-activity?activityId=${id}&page=${page}&size=${size}`;
        return axios.post(url);
    },

    getPostsAndVideosOfDoctor(id) {
        const url = `/doctor/published-contents/${id}`;
        return axios.get(url);
    },

    getDoctorAnswerQuestionsList(id) {
        const url = `/doctor/answer-question-list/${id}?offset=0&limit=20`;
        return axios.get(url);
    },

    configSchedulingStatus(schedulingType, status) {
        const url = `doctor/scheduling/config/status?schedulingType=${schedulingType}&status=${status}`;
        return axios.post(url);
    },

    //------------------------------------------------------------------------------------
    // EXAMS
    //------------------------------------------------------------------------------------
    ExamCheckIsAssign(examId) {
        const username = store.getters.mobileNumber;
        const url = `/exam-assign/check-is-assign/to/username/${username}/exam/${examId}`;
        return axios.get(url);
    },
    AssignMe(examId) {
        const url = `/exam-assign/me`;
        return axios.post(url, {
            userUsername: store.getters.mobileNumber,
            examId: examId
        });
    },

    // User/Home & General
    SlideShowListAll() {
        const url = `/slideshow/list-all`;
        return axios.get(url);
    },
    getMoodStaticsForaWeek(StartDate, EndDate) {
        const url = `/mood/statistics?startDate=${StartDate}&endDate=${EndDate}`;
        return axios.post(url);
    },
    searchContentService(parmameter) {
        const url = `/search/all?searchParam=${parmameter}`;
        return axios.get(url);
    },
    likeComment(commentId, Type, RefId) {
        const Data = {
            "commentId": commentId,
            "type": Type,
            "refId": RefId,
            "userMobile": store.state.mobileNumber
        }
        const url = `/comment/like`;
        return axios.post(url, Data);
    },
    unLikeComment(commentId, Type, RefId) {
        const Data = {
            "commentId": commentId,
            "type": Type,
            "refId": RefId,
            "userMobile": store.state.mobileNumber
            // refId = Post / Video / Podcast Id
        }
        const url = `/comment/unlike`;
        return axios.post(url, Data);
    },
    reportContent(ContentKey, Type, Description) {
        const Data = {
            "contentKey": ContentKey,
            // ContentKey = Post / Video / Podcast Id
            "type": Type,
            "description": Description
        }
        const url = `/content/block`;
        return axios.post(url, Data)
    },
    deleteVideoComment(id) {
        const url = `/video/comment/delete/${id}`;
        return axios.delete(url);
    },

    getUserInformationByPhoneNumber(mobile) {
        const url = `/client/user/info/${encodeURIComponent(mobile)}`;
        return axios.get(url);
    },
    FollowADoctor(DoctorId, UserId) {
        const url = `/doctor/follow?doctorId=${DoctorId}&userId=${UserId}`;
        axios.post(url);
    },
    unFollow(DoctorId, UserId) {
        const url = `/doctor/unfollow?doctorId=${DoctorId}&userId=${UserId}`;
        return axios.post(url);
    },


    // Questions
    UserQuestion() {
        const url = `/user-question/common-list`;
        return axios.get(url);
    },

    // Appointments
    getDoctorTimesheets(fromDate, toDate, pageNumber, pageSize) {
        const url = `doctor/timesheet/between/${fromDate}/${toDate}/me?page=${pageNumber}&size=${pageSize}&sort=valueDate,asc`;
        return axios.get(url);
    },

    getDoctorTimesheetByUser(doctorID,fromDate, toDate, pageNumber, pageSize) {
        const url = `doctor/timesheet/${doctorID}/${fromDate}/${toDate}`;
        return axios.get(url);
    },

    saveDoctorTimesheet(timesheet) {
        const url = `doctor/timesheet`;
        return axios.post(url, timesheet);
    },

    saveDoctorTimesheetDetail(timesheetDetail, timesheetId) {
        const url = `doctor/timesheet/details/${timesheetId}`;
        return axios.post(url, timesheetDetail);
    },

    updateDoctorTimesheetDetail(timesheetDetail, timesheetId) {
        const url = `doctor/timesheet/details/${timesheetId}`;
        return axios.put(url, timesheetDetail);
    },

    deleteDoctorTimesheetDetail(id) {
        const url = `/doctor/timesheet/details/${id}`;
        return axios.delete(url);
    },

    getCurrentDoctorTimesheetDetail() {
        const url = `doctor/timesheet/reserved/today/now?size=3&sort=fromTime&page=0`;
        return axios.get(url);
    },

    getDoctorTimesheetDetailsByDoctorIdAndDate(doctorId, date) {
        const url = `/doctor/timesheet/at/${date}/${doctorId}`;
        return axios.get(url);
    },

    getDoctorTimesheetDetailsByDoctorIdAndDateAndSessionType(doctorId, date, sessionType) {
        const url = `/doctor/timesheet/at/${date}/${doctorId}/${sessionType}`;
        return axios.get(url);
    },

    registerAppointment(appointment) {
        const url = `/client/user/appointment`;
        return axios.post(url, appointment);
    },

    gatAppointmentsList(fromDate, toDate) {
        const url = `/client/user/appointment/list-all/between/${fromDate}/${toDate}/me`;
        return axios.get(url);
    },

    getClosestEmptyTimesheet(doctorId, sessionType) {
        const url = `/doctor/timesheet/closest-empty-time/${doctorId}/${sessionType}`;
        return axios.get(url);
    },

    getDetailedTimeSheetDetail(timeSheetDetailId) {
        const url = `/doctor/timesheet/details/${timeSheetDetailId}/detailed`;
        return axios.get(url);
    },
}
