import Vue from "vue";
import App from "@/App";

//------------------------------------------------------------------------------------------
// Vue Router
//------------------------------------------------------------------------------------------
import router from "@/router";

//------------------------------------------------------------------------------------------
// axios
//------------------------------------------------------------------------------------------
import axios from "@/http/httpApplication.js";
import VueAxios from 'vue-axios';
Vue.prototype.$http = axios;
Vue.prototype.$axios = axios;

Vue.use(VueAxios, axios);

//------------------------------------------------------------------------------------------
// Vuex Store
//------------------------------------------------------------------------------------------
import store from "@/store/store";

//------------------------------------------------------------------------------------------
// Image Cropeer
//------------------------------------------------------------------------------------------
import Croppa from 'vue-croppa'
import 'vue-croppa/dist/vue-croppa.css'
Vue.use(Croppa)

//------------------------------------------------------------------------------------------
// i18n
//------------------------------------------------------------------------------------------
import i18n from '@/plugins/i18n/i18n'

//------------------------------------------------------------------------------------------
// VeeValidate
//------------------------------------------------------------------------------------------
import VeeValidate from 'vee-validate';
Vue.use(VeeValidate, {
  i18n,
});

//------------------------------------------------------------------------------------------
// JQuery
//------------------------------------------------------------------------------------------
// import jQuery from "jquery";
// Vue.use({
//   install(V) {
//     V.$jQuery = V.prototype.$jQuery = jQuery;
//   },
// });

//------------------------------------------------------------------------------------------
// vuetify
//------------------------------------------------------------------------------------------
import vuetify from "@/plugins/vuetify.js"; // path to vuetify export
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";

//------------------------------------------------------------------------------------------
// Import Bootstrap an BootstrapVue CSS files (order is important)
//------------------------------------------------------------------------------------------
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "./assets/css/bootstrap-custom.scss";

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);

//------------------------------------------------------------------------------------------
// Jalaali moment
//------------------------------------------------------------------------------------------
// Vue.use(require('vue-moment'));
Vue.use(require('@/utils/vue-moment.js'));
require('moment/locale/fa');
//------------------------------------------------------------------------------------------
// Map
//------------------------------------------------------------------------------------------
import { mapir, mapMarker } from "mapir-vue";
// import "mapir-vue/dist/mapir-vue.css";

Vue.component("mapir", mapir);
Vue.component("mapMarker", mapMarker);

//------------------------------------------------------------------------------------------
// Toast
//------------------------------------------------------------------------------------------
import Toast, { POSITION } from "vue-toastification";
import "vue-toastification/dist/index.css";
const toastOptions = {
  position: POSITION.BOTTOM_CENTER,
  timeout: 3000,
  draggable: false,
  pauseOnFocusLoss: false,
  pauseOnHover: false,
  hideProgressBar: true,
  showCloseButtonOnHover: true,
};
Vue.use(Toast, toastOptions);


//------------------------------------------------------------------------------------------
// Vue Meta
// ------------------------------------------------------------------------------------------
import VueMeta from 'vue-meta';
Vue.use(VueMeta);

//------------------------------------------------------------------------------------------
// Sweet alert
//------------------------------------------------------------------------------------------
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

const options = {
  confirmButtonColor: '#20B1EA',
  cancelButtonColor: '#546E7A',
  denyButtonColor: '#D32F2F',
};

Vue.use(VueSweetalert2, options);

//------------------------------------------------------------------------------------------
// Iconly
//------------------------------------------------------------------------------------------
import IconlyComponent from "@/components/Iconly";
Vue.component("iconly", IconlyComponent);

//------------------------------------------------------------------------------------------
// Bottom Sheet
//------------------------------------------------------------------------------------------
import VueBottomSheet from "@webzlodimir/vue-bottom-sheet";
Vue.use(VueBottomSheet);

//------------------------------------------------------------------------------------------
// infinite Scroll
//------------------------------------------------------------------------------------------
import InfiniteLoading from 'vue-infinite-loading';
Vue.use(InfiniteLoading, { /* options */ });


//------------------------------------------------------------------------------------------
// Reset CSS
//------------------------------------------------------------------------------------------
import "ress/dist/ress.min.css";
import "normalize.css";
import "./assets/css/custom.css";

//------------------------------------------------------------------------------------------
// vue plyr
//------------------------------------------------------------------------------------------
import VuePlyr from 'vue-plyr'
import 'vue-plyr/dist/vue-plyr.css'
Vue.use(VuePlyr, {
  plyr: {}
})

//------------------------------------------------------------------------------------------
// Vue Mask Input
//------------------------------------------------------------------------------------------
import VueMask from 'v-mask'
Vue.use(VueMask);
//------------------------------------------------------------------------------------------
// Vue notification
//------------------------------------------------------------------------------------------
import Notifications from 'vue-notification'
Vue.use(Notifications)

//------------------------------------------------------------------------------------------
// Toggle Button
//------------------------------------------------------------------------------------------
import ToggleButton from 'vue-js-toggle-button'
Vue.use(ToggleButton)

//------------------------------------------------------------------------------------------
// Service Worker
//------------------------------------------------------------------------------------------
import './registerServiceWorker'

Vue.config.productionTip = false;

Vue.mixin({
  data(){
    return {
      isMobileCheck: /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
    }
  }
})

window.isMobileCheck = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)


new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(App)
}).$mount("#app");
